import React, { Suspense, useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { Routes } from "./routing/Routes";
import { QueryClientProvider } from "react-query";
//import { ReactQueryDevtools } from "react-query/devtools";
import { queryClient } from "../app/react-query/queryClient";
import { ToastContainer, toast } from "react-toastify";
import { useIdleTimer } from "react-idle-timer";
import "react-toastify/dist/ReactToastify.css";
import { getLogout } from "./pages/auth/hooks/useUser";
import { clearStoredUser } from "./pages/auth/user-storage";
import { FetchAndUnmask, MaskAndSave, startIpRefresh, TokenRefresh } from "core/helpers";

type Props = {
  basename: string;
};

const Logout = async () => {
  const login = FetchAndUnmask("userLogin");

  if (login === "true") {
    MaskAndSave("userLogin", "false");
    await getLogout();
    clearStoredUser();
    alert("Your session has expired. You will be logged out.");
    window.location.href = "/auth/login";
  } else {
    clearStoredUser();
  }
};

const App: React.FC<Props> = ({ basename }) => {
  const handleOnIdle = (event) => {
    Logout();
  };

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: window.ENV.logoutTime,
    //timeout: 10000 * 60 * 10,
    onIdle: handleOnIdle,
    debounce: 500,
  });

  startIpRefresh();
  TokenRefresh()

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Routes />
        <ToastContainer />
      </BrowserRouter>
      {/* <ReactQueryDevtools  /> */}
    </QueryClientProvider>
  );
};

export { App };
