import { AxiosResponse } from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';

// import type { User } from '../../../../../shared/types';
import {
    axiosInstance,
    // getJWTHeader,
    axiosInstance2,
    axiosInstanceOnboarding,
} from '../../../../axiosInstance';
import { ErrorHandler, FetchAndUnmask } from 'core/helpers';
import { ErrorToast, SuccessToast } from 'app/components/toast';
import { queryKeys } from '../../../react-query/constants';
import { revenueService } from '../services/revenueService';
import { CreateInvoice } from '../interface/revenueInterface';

async function getAllMda(): Promise<any | null> {
    const token: string | null = sessionStorage.getItem('token')
    axiosInstanceOnboarding.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    const { data }: AxiosResponse<any> = await axiosInstanceOnboarding.get(
        `https://devgate.fgntreasury.gov.ng//api/organization/?page=0&size=20`,
    );
    return data;

    //   const { data, headers }: AxiosResponse<{ user: any }> =
    //   await axiosInstance2.get(
    //     `/api/organizations/outflow-payment-summary?${filter}`
    //   );
    // return { data, totalSize: headers["x-total-count"] };
}

async function getAllMdawithFilter(filter: any): Promise<any | null> {
    const { data }: AxiosResponse<any> = await axiosInstance2.get(
        `/services/organization/api/v2/organizations/filter?${filter}&size=200`,
    );
    return data;
}

// export async function createInvoice(
//   expiry_date: string,
//   values: any
// ): Promise<any | null> {
//   const { data }: AxiosResponse<any> = await axiosInstance2.post(
//     `/services/collection/api/invoices/create`,
//     values
//   );
//   return data;
// }

export async function requestReviewed(id: any): Promise<any | null> {
    const { data }: AxiosResponse<any> = await axiosInstance2.post(
        `/services/organization/api/organizations/review-awaiting-requests/${id.id}?status=${id.type}`,
        { revCode: id?.revenueCode, reason: id?.reason },
    );
    return data;
}

export async function getAllInvoiceInfo(invoice: string): Promise<any | null> {
    const { data }: AxiosResponse<any> = await axiosInstance2.get(
        `/services/collection/api/invoices/number/${invoice}/full`,
    );
    return data;
}

async function getPayRef(payRef: string): Promise<any | null> {
    const { data }: AxiosResponse<any> = await axiosInstance2.get(
        // `/services/collection/api/payments-by-grr/${payRef}`
        `services/collection/api/invoice-by-payment-reference-number/${payRef}`,
    );
    return data;
}

export async function getPaymentDetails(paymentId: string): Promise<any | null> {
    const token: string | null = FetchAndUnmask('token');

    axiosInstance2.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const { data }: AxiosResponse<any> = await axiosInstance2.get(
        `/services/collection/api/payment-by-receipt/${paymentId}`,
    );
    return data;
}

export async function sendEmailRecieptToUserInvoiceId(invoice: string): Promise<any | null> {
    const { data }: AxiosResponse<any> = await axiosInstance2.post(
        `/services/collection/api/payments/${invoice}/send-payment-notification`,
    );
    return data;
}

async function getServiceType(id: any): Promise<any | null> {
    const { data }: AxiosResponse<any> = await axiosInstance2.get(
        `/services/collection/api/organizations/${id}/services`,
    );
    return data;
}

async function getAwaitingRequest(req): Promise<any | null> {
    const token: string | null = FetchAndUnmask('token');

    axiosInstance2.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    //console.log(req);

    if (req) {
        const { data }: AxiosResponse<any> = await axiosInstance2.get(
            `/services/organization/api/organizations/requests/awaiting-requests?${req}`,
        );
        return data;
    } else {
        const { data }: AxiosResponse<any> = await axiosInstance2.get(
            `/services/organization/api/organizations/requests/awaiting-requests`,
        );
        return data;
    }
}

async function getInvoiceRequest(values: any): Promise<any | null> {
    const { data }: AxiosResponse<any> = await axiosInstance.post(
        `/services/collection/api/payment/invoice-request`,
        values,
    );

    return data;
}

async function requestSubscribtion(values: any): Promise<any | null> {
    const { data }: AxiosResponse<any> = await axiosInstance.post(
        `/services/collection/api/payment/subscribtion`,
        values,
    );

    return data;
}

async function getRevenuePaymentRequesRefid(values: any): Promise<any | null> {
    const { data }: AxiosResponse<any> = await axiosInstance.post(
        `/services/collection/api/payment/revenue-payment-request-by-refid`,

        values,
    );

    return data;
}

// export function useGetAllMda(): any {
//   const queryClient = useQueryClient();

//   const {
//     data: mdaList,
//     isError,
//     isLoading,
//     error,
//   } = useQuery(queryKeys.mdaList, () => getAllMda());

//   return { mdaList, isLoading, error };
// }

export function useGetAllMda(): any {
    return useMutation(() => getAllMda(), {
        onSuccess: (res) => {
            ////console.log(res);
        },
        onError: (err: any) => {
            ErrorHandler(err);
        },
    });
}

export function useGetPaginatedAllMda(): any {
    async function getMDAs(filter): Promise<any | null> {


           const token: string | null = sessionStorage.getItem('token')

    axiosInstanceOnboarding.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        const { data }: AxiosResponse<{ user: any }> = await axiosInstanceOnboarding.get(
            `/api/organization`,
        );
        return { data};
    }

    return useMutation((filter) => getMDAs(filter), {
        onSuccess: (res) => {},
        onError: (err: any) => {
            ErrorHandler(err);
        },
    });
}

export function useGetMdaSUsers(): any {
    async function getMdaUsers(orgIds: string): Promise<any | null> {
        const token: string | null = FetchAndUnmask('token');

        axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        const { data }: AxiosResponse<any> = await axiosInstance.get(
            `/services/organization/api/organizations/list?orgIds=${orgIds}`,
        );
        return data;
    }

    return useMutation((ordIds: string) => getMdaUsers(ordIds), {
        onSuccess: (res) => {
            ////console.log(res);
        },
        onError: (err: any) => {
            ErrorHandler(err);
        },
    });
}
export function useGetAllMdaFilter(): any {
    return useMutation((filter) => getAllMdawithFilter(filter), {
        onSuccess: (res) => {
            ////console.log(res);
        },
        onError: (err: any) => {
            ErrorHandler(err);
        },
    });
}

export function useOrgnizationService(id: any): any {
    const queryClient = useQueryClient();

    const {
        data: serviceTypeList,
        isError,
        isLoading,
        error,
    } = useQuery(queryKeys.serviceTypeList, () => getServiceType(id));

    return { serviceTypeList, isLoading };
}

export function useInvoiceRequest(): any {
    return useMutation((value) => getInvoiceRequest(value), {
        onSuccess: (res) => {
            //console.log(res);
        },
        onError: (err: any) => {
            ErrorHandler(err);
        },
    });
}

export function useRequestSubscription(): any {
    return useMutation((value) => requestSubscribtion(value), {
        onSuccess: (res) => {
            // SuccessToast("Your request was successful");
        },
        onError: (err: any) => {
            ErrorHandler(err);
        },
    });
}

export function useGetAwaitingRequest(): any {
    return useMutation((req) => getAwaitingRequest(req), {
        onSuccess: (res) => {},
        onError: (err: any) => {
            ErrorHandler(err);
        },
    });
}

export function useRequestReviewed(): any {
    return useMutation((id) => requestReviewed(id), {
        onSuccess: (res) => {},
        onError: (err: any) => {
            ErrorHandler(err);
        },
    });
}

export function useGetPayRef(): any {
    return useMutation((value: string) => getPayRef(value), {
        onSuccess: (res) => {
            //console.log(res);
        },
        onError: (err: any) => {
            ErrorHandler(err);
        },
    });
}

export function useDownloadInflowReport(): any {
    async function inFlow(filter): Promise<any | null> {
        const data: AxiosResponse<{ user: any }> = await axiosInstance2.get(
            `/services/collection/api/inflow-controller?${filter}`,
        );
        return data;
    }
    return useMutation((filter) => inFlow(filter), {
        onSuccess: (res) => {},
        onError: (err: any) => {
            ErrorHandler(err);
        },
    });
}

export function useDownloadOutflowReport(): any {
    async function outFlow(filter): Promise<any | null> {
        const data: AxiosResponse<{ user: any }> = await axiosInstance2.get(
            `/services/organization/api/organizations/outflow-controller ${filter}`,
        );
        return data;
    }
    return useMutation((filter) => outFlow(filter), {
        onSuccess: (res) => {},
        onError: (err: any) => {
            ErrorHandler(err);
        },
    });
}

//New definition

export const useCreateInvoice = () =>
    useMutation({
        mutationFn: (dataToSend: CreateInvoice) => revenueService.createInvoice(dataToSend),
    });

export const useGetServiceList = () =>
    useMutation({
        mutationFn: (id: string) => revenueService.getServiceList(id),
    });

export const useConfirmPayment = () =>
    useMutation({
        mutationFn: (value) => revenueService.confirmPayment(value),
        onError(error, variables, context) {
            ErrorHandler(error);
        },
    });

export const usePaymentList = () =>
    useMutation({
        mutationFn: () => revenueService.getPaymentList(),
    });

export const useGetMdaWithService = () =>
    useMutation({
        mutationFn: (filter: string) => revenueService.getMdaWithService(filter),
        onError(error: any, variables, context) {
            let message = '';

            if (error.response) {
                const errMessage = error.response.data;
                message = errMessage.message ? errMessage.message : 'Something went wrong.';
            } else {
                message = error.message;
            }
            ErrorToast(message);
        },
    });

export const useSubmitPaymentRev = () =>
    useMutation({
        mutationFn: (dataToSend) => revenueService.submitPaymentRevenue(dataToSend),
    });

export const useRevenuePaymentRequestRefId = () =>
    useMutation({
        mutationFn: (dataToSend) => revenueService.getRevenuePaymentRequestRefId(dataToSend),
    });

export const useReceiptByInvoice = () =>
    useMutation({
        mutationFn: (invoiceId: string) => revenueService.sendEmailReceiptToUserInvoiceId(invoiceId),
    });

export const useReceiptByPayment = () =>
    useMutation({
        mutationFn: (paymentId: string) => revenueService.sendEmailReceiptToUserPaymentId(paymentId),
    });

export const useGetInvoice = () =>
    useMutation({
        mutationFn: (paymentId: string) => revenueService.getInvoice(paymentId),
    });

export const useGetMdaBySector = () =>
    useMutation({
        mutationFn: (search: string) => revenueService.searchMDa(search),
    });

export const useGetSector = () =>
    useMutation({
        mutationFn: () => revenueService.getSector(),
    });
