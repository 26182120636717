import { AxiosResponse } from 'axios';
import { axiosInstance, axiosInstanceOnboarding, axiosInstanceKC } from 'axiosInstance';
import { FetchAndUnmask } from './EncryptStorage';

// Utility function to create service methods
export const createServiceMethod = (
    url: string | ((...args: any[]) => string),
    method: 'get' | 'post' = 'get',
    transformResponse?: (response: AxiosResponse<any>) => any,
) => {
    return async (params: any = {}): Promise<any | null> => {
        // Determine the actual URL
        const resolvedUrl = typeof url === 'function' ? url(params) : url;
        const response: AxiosResponse<any> = await axiosInstance[method](resolvedUrl, { ...params });
        return transformResponse ? transformResponse(response) : response.data;
    };
};

export const createServiceMethodDevgate = (
    url: string | ((...args: any[]) => string),
    method: 'get' | 'post' = 'get',
    transformResponse?: (response: AxiosResponse<any>) => any,
) => {
    return async (params: any = {}): Promise<any | null> => {
        // Determine the actual URL
        const resolvedUrl = typeof url === 'function' ? url(params) : url;
        const token: string | null = FetchAndUnmask('token')

        axiosInstanceOnboarding.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        const response: AxiosResponse<any> = await axiosInstanceOnboarding[method](resolvedUrl, { ...params });
        return transformResponse ? transformResponse(response) : response.data;
    };
};

export const createServiceMethod_keyClock = (
    url: string | ((...args: any[]) => string),
    method: 'get' | 'post' = 'get',
    transformResponse?: (response: AxiosResponse<any>) => any,
    isFormData: boolean = false, // New parameter to indicate if the request should send form data
) => {
    return async (params: any = {}): Promise<any | null> => {
        // Determine the actual URL
        const resolvedUrl = typeof url === 'function' ? url(params) : url;

        // Get the token from sessionStorage
        const token: string | null = sessionStorage.getItem('token');

        // Set the Authorization header
        axiosInstanceKC.defaults.headers.common['Authorization'] = `Bearer ${token}`;

        // Prepare the request body
        let dataToSend = { ...params };

        if (isFormData) {
            // If `isFormData` is true, we create a new URLSearchParams object
            const searchParams = new URLSearchParams();

            // Convert params into URLSearchParams format
            Object.keys(params).forEach((key) => {
                searchParams.append(key, params[key]);
            });

            // Set the appropriate Content-Type header for application/x-www-form-urlencoded
            axiosInstanceKC.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded';

            dataToSend = searchParams; // Use URLSearchParams as the payload
        } else {
            // Reset Content-Type to 'application/json' if not using form data
            axiosInstanceKC.defaults.headers.common['Content-Type'] = 'application/json';
        }

        // Send the request based on the method type
        let response: AxiosResponse<any>;

        if (method === 'post') {
            response = await axiosInstanceKC.post(resolvedUrl, dataToSend);
        } else {
            response = await axiosInstanceKC.get(resolvedUrl, { params: dataToSend });
        }

        // Apply transformation if needed, or return the response data
        return transformResponse ? transformResponse(response) : response.data;
    };
};
