import { authService } from 'app/pages/auth/service/authServices';
import { FetchAndUnmask, MaskAndSave } from './EncryptStorage';
import { LoginProps } from 'app/pages/auth/hooks/useAuth';

const Refresh = async () => {
    const token = FetchAndUnmask('token');
    const refresh_token = FetchAndUnmask('refresh_token');

    // Check if refresh token exists
    if (!token && !refresh_token) {
        console.error('No refresh token available');
        return;
    }

    // Define the payload for refreshing the token
    const payload: LoginProps = {
        client_id: window.ENV.client_id, // Replace with your actual client ID
        grant_type: 'refresh_token',
        client_secret: window.ENV.client_secret,
        refresh_token: refresh_token,
    };

    try {
        // Call the refresh token API using your custom axios instance
        const data = await authService.login(payload);

        // If successful, store the new access token
        if (data?.access_token && data.refresh_token) {
            MaskAndSave('token', data.access_token);
            MaskAndSave('refresh_token', data.refresh_token);
        }
    } catch (error) {}
};

export const TokenRefresh = () => {
    // Initial call to refresh the token
    Refresh();

    // Set interval to refresh the token every 10 minutes (600000ms)
    const intervalId = setInterval(
        () => {
            Refresh();
        },
        window.ENV.refreshTokenTime || 3 * 60 * 1000,
    ); // Refresh every 10 minutes

    // Cleanup interval if necessary (e.g., when the component is unmounted)
    return () => clearInterval(intervalId);
};
