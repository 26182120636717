// import { User } from '../../../shared/types';

import { FetchAndUnmask, MaskAndSave } from "../../../../core/helpers";

const USER_LOCALSTORAGE_KEY = 'lazyday_user';

// helper to get user from localstorage
export function getStoredUser(): any | null {
  const storedUser = FetchAndUnmask(USER_LOCALSTORAGE_KEY);
  return storedUser ? JSON.parse(storedUser) : null;
}

export function getStoredUse(): any | null {
  const storedUser = FetchAndUnmask('authentication');
  return storedUser ? JSON.parse(storedUser) : null;
}

export function setStoredUser(user: any): void {
  MaskAndSave(USER_LOCALSTORAGE_KEY, JSON.stringify(user));
}

export function clearStoredUser(): void {
  sessionStorage.removeItem(USER_LOCALSTORAGE_KEY);
  sessionStorage.removeItem('orgId');
  sessionStorage.removeItem('userDetails');
  sessionStorage.removeItem('token');
  sessionStorage.removeItem('names');
  sessionStorage.removeItem('login');
  sessionStorage.removeItem('realm')
  sessionStorage.clear()
  localStorage.clear()
}
