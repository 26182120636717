import { createServiceMethod, createServiceMethodDevgate } from 'core/helpers/CreateServiceMethod';

const revenueService = {
    getMdaWithService: createServiceMethod(
        (filter: string) =>
            `/services/organization/api/organizations/organizations-with-active-services-and-visibility?${filter}`,
        'get',
        (response) => ({ data: response.data, totalSize: response.headers['x-total-count'] }),
    ),
    getAllMda: createServiceMethod(`/services/organization/api/v2/organizations?size=1000`),
    searchMda: createServiceMethod(`/services/organization/api/organizations/find-by-sector-code`),
    createInvoice: createServiceMethod(`/services/collection/api/invoices/create`, 'post'),
    getServiceList: createServiceMethod((id: string) => `/services/organization/api/organizations/${id}/allservices`),
    confirmPayment: createServiceMethod(`/services/collection/api/pay`, 'post'),
    getPaymentList: createServiceMethod(`/services/collection/api/payment-gateways`),
    submitPaymentRevenue: createServiceMethod(`/services/collection/api/payment/revenue-payment-request`, 'post'),
    getRevenuePaymentRequestRefId: createServiceMethod(
        `/services/collection/api/payment/revenue-payment-request-by-refid`,
        'post',
    ),
    sendEmailReceiptToUserInvoiceId: createServiceMethod(
        (invoice: string) => `/services/collection/api/payments/${invoice}/send-payment-notification`,
        'post',
    ),
    sendEmailReceiptToUserPaymentId: createServiceMethod(
        (paymentId: string) => `/services/collection/api/payments/${paymentId}/send-payment-notification-by-receipt`,
        'post',
    ),
    getInvoice: createServiceMethod((invoice: string) => `/services/collection/api/invoices/number/${invoice}`),
    searchMDa: createServiceMethod(
        (search: string) => `/services/organization/api/organizations/find-by-sector-code/${search}`,
    ),
    getSector: createServiceMethodDevgate(`/api/organization/sectors`),
};

export { revenueService };
