import axios, { AxiosRequestConfig } from 'axios';
import UAParser from 'ua-parser-js';
import { FetchAndUnmask } from '../core/helpers/EncryptStorage';

// Get device information
export const getDeviceInfo = () => {
    const parser = new UAParser();
    const result = parser.getResult();
    return {
        browser: result.browser.name,
        browserVersion: result.browser.version,
        os: result.os.name,
        osVersion: result.os.version,
        device: result.device.model || 'Desktop',
    };
};

// Get location using geolocation
const getLocation = (): Promise<{ longitude: number; latitude: number }> => {
    return new Promise((resolve, reject) => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) =>
                    resolve({
                        longitude: position.coords.longitude,
                        latitude: position.coords.latitude,
                    }),
                (error) => reject(error),
                { timeout: 10000 },
            );
        } else {
            reject(new Error('Geolocation is not supported by this browser.'));
        }
    });
};

// Generate JWT headers
export function getJWTHeader(user: any): Record<string, string> {
    return { Authorization: `Bearer ${user.token}` };
}

export function getJWTHeaderPreLogin(token: string): Record<string, string> {
    return { Authorization: `Bearer ${token}` };
}

// Fetch token securely
const token: string | null = sessionStorage.getItem('token')
const token2: string | null = FetchAndUnmask('token');

// Axios instance configuration
const createAxiosInstance = (baseURL: string) => {
    const instance = axios.create({ baseURL });

    const addHeaders: any = async (config: AxiosRequestConfig) => {
        const deviceInfo = getDeviceInfo();
        let longitude = '';
        let latitude = '';

        // // Attempt to get location
        // try {
        //     const location = await getLocation();
        //     longitude = location.longitude.toString();
        //     latitude = location.latitude.toString();
        // } catch (error) {
        //     //console.warn("Error fetching location:", error);
        // }

        config.headers = {
            ...config.headers,
            // LONG: longitude,
            // DEVICE: deviceInfo.device,
            // IP: localStorage.getItem('ipAddress'),
            // LAT: latitude,
            ...((token || token2) && { Authorization: `Bearer ${token2 ?? token}` }),
        };

        return config;
    };

    // Request interceptor to add headers
    instance.interceptors.request.use(addHeaders, (error) => Promise.reject(error));

    // Response interceptor
    instance.interceptors.response.use(
        (response) => response,
        (error) => Promise.reject(error),
    );

    return instance;
};

// Create instances for different base URLs
const axiosInstance = createAxiosInstance(window.ENV.apiUrl);
const axiosInstanceOnboarding = createAxiosInstance('https://devgate.fgntreasury.gov.ng');
const axiosInstanceKC = createAxiosInstance('https://kc.fgntreasury.gov.ng/');
const axiosInstance2 = createAxiosInstance(window.ENV.apiUrl);
const axiosInstance3 = createAxiosInstance(window.ENV.apiUrl);

export { axiosInstance, axiosInstance2, axiosInstance3, axiosInstanceOnboarding,axiosInstanceKC };
