import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';

import { useFormik } from 'formik';
import { Modal } from 'react-bootstrap';

import { NumericFormat } from 'react-number-format';

import {
  useRequestOnboardingStepentitydetails,
  useVerificationcode,
  useRequestOnboardingSendVerificatoinCode,
  useGetMdas,
} from '../hooks/useUser';

import { infoBlue, phone } from 'core/assets/icons';
import OTPInput from 'react-otp-input';
import { FetchAndUnmask } from 'core/helpers';
import DropDown from 'app/components/dropdown';
import { useGetAllMda, useGetPaginatedAllMda, useGetSector } from 'app/pages/payments/hooks/useRevenuePayment';
import { ErrorToast } from 'app/components/toast';

const Schema = Yup.object().shape({
    registeredEntityName: Yup.string()
        .required('Registered Entity Name is required')
        .max(50, 'Text field must be at most 50 characters long'),
    mdaAdminCode: Yup.string()
        .required('MDA Code is required')
        .max(15, 'Text field must be at most 15 characters long')
        .min(15, 'Text field must be at most 15 characters long'),
    mDASector: Yup.string().required('MDA Sector is required').max(50, 'Text field must be at most 50 characters long'),
    decription: Yup.string().required('MDA Mandate is required').min(5, 'Text field must be longer than 5 characters'),
    entityPhysicalAddress: Yup.string()
        .required('Entity Physical Address is required')
        .max(50, 'Text field must be at most 50 characters long'),
    entityCountry: Yup.string().required('Entity Country is required'),
    entityStateProvince: Yup.string()
        .required('Entity State/Province is required')
        .max(50, 'Text field must be at most 50 characters long'),
    entityCityRegion: Yup.string()
        .required('Entity City Region is required')
        .max(50, 'Text field must be at most 50 characters long'),
    entityphoneNumber: Yup.string()
        .required('Entity Phone Number is required')
        .max(50, 'Text field must be at most 50 characters long'),
    entityEmailAddress: Yup.string()
        .required('Entity Email Address is required')
        .max(50, 'Text field must be at most 50 characters long'),
});

let stepJson: string;
let stepOneJson;

const initialValues = {
    registeredEntityName: '',
    mdaAdminCode: '',
    tradingName: '',
    mDASector: '',
    mDAParent: '',
    decription: '',
    entityPhysicalAddress: '',
    entityCountry: '',
    entityStateProvince: '',
    entityCityRegion: '',
    entityphoneNumber: '',
    entityEmailAddress: '',
    websiteURL: '',
    twitterHandle: '',
    facebokkHandle: '',
    instagramHandle: '',
    linkedInHandle: '',
};

interface Step1Props {
    handleNextStep: () => void;
    handlePreviousStep: () => void;
}

const StepThree: React.FC<Step1Props> = ({ handleNextStep, handlePreviousStep }) => {
    const [show, setShow] = useState<boolean>(true);
    const [refreshFilter, setRefreshFilter] = useState<boolean>(false);
    const [hasBnFiltered, setHasBnFiltered] = useState<boolean>(false);

    const { data, mutate, isLoading } = useRequestOnboardingStepentitydetails();

    const { data: getSector, mutate: mutateGetSector, isLoading: loadingSector } = useGetSector();


    const { data: mdaNameList, mutate: mdaMutate, isLoading: allMdaLoading } = useGetAllMda();

console.log(mdaNameList?.data);




    useEffect(() => {
        mutateGetSector();
        mdaMutate()

      
    }, []);

    const updateFilter = (value: string, fn: any) => {
        fn(value);
        if (value?.length === 0 && !refreshFilter && hasBnFiltered) {
            setRefreshFilter(true);
            setHasBnFiltered(false);
        } else {
            setRefreshFilter(false);
            setHasBnFiltered(false);
        }
    };

  const formik = useFormik({
    initialValues,
    validationSchema: Schema,
    onSubmit: (values) => {
        // Construct payload dynamically to exclude empty parentId
        const payload: {
            registeredName: string;
            tradingName: string;
            mdaAdminCode: string;
            description: string;
            contactDto: {
                physicalAddress: string;
                phoneNumber: string;
                websiteUrl: string;
                email: string;
                twitterHandle: string;
                country: string;
                state: string;
                city: string;
                instagram: string;
                facebook: string;
                linkedIn: string;
            };
            kycDocuments: {};
            sectorId: string;
            parentId?: string;
        } = {
            registeredName: formik.values.registeredEntityName,
            tradingName: formik.values.tradingName,
            mdaAdminCode: formik.values.mdaAdminCode,
            description: formik.values.decription,
            contactDto: {
                physicalAddress: formik.values.entityPhysicalAddress,
                phoneNumber: formik.values.entityphoneNumber,
                websiteUrl: formik.values.websiteURL,
                email: formik.values.entityEmailAddress,
                twitterHandle: formik.values.twitterHandle,
                country: formik.values.entityCountry,
                state: formik.values.entityStateProvince,
                city: formik.values.entityCityRegion,
                instagram: formik.values.instagramHandle,
                facebook: formik.values.facebokkHandle,
                linkedIn: formik.values.linkedInHandle,
            },
            kycDocuments: {},
            sectorId: formik.values.mDASector
        };

        // Add parentId only if it is not empty
        if (formik.values.mDAParent) {
            payload.parentId = formik.values.mDAParent;
        }

        mutate(payload, {
            onSuccess: (res: any) => {
                sessionStorage.setItem('orgId', res?.data?.id);
                handleNextStep();
            },
            onError: (error) => {
                ErrorToast(error.response.data.message);
            },
        });
    },
});


    var names: any = [];
    let tt: any;

    stepJson = FetchAndUnmask('names') as string;

    stepOneJson = stepJson !== null ? JSON.parse(stepJson) : [];

    useEffect(() => {
        stepJson = FetchAndUnmask('names') as string;

        stepOneJson = stepJson !== null ? JSON.parse(stepJson) : [];

        tt = stepOneJson[1] ? stepOneJson[1] : '';

        names = stepOneJson;
    }, [handleNextStep, data, names, tt]);

    const [otp, setOtp] = useState('');

    const {
        data: sendVerifyData,
        mutate: sendVerifyMuute,
        isLoading: isVerfyLoading,
    } = useRequestOnboardingSendVerificatoinCode();

    const sendVerification = () => {
        sendVerifyMuute(stepOneJson['0'].email, {
            onSuccess: (res: any) => {},
        });
    };


    const [showConfirm, setConfirm] = useState<any>(false);

    const confrimModal = () => {
        return (
            <Modal
                dialogClassName="  m-auto px-5 modal-card"
                aria-hidden="true"
                show={showConfirm}
                onHide={() => setConfirm(false)}
                animation
                centered
                size="lg"
                backdrop={'static'}
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Body className="rounded">
                    <>
                        <div className="m-3 mx-15">
                            <div className=" d-flex flex-column align-items-center ">
                                <img src={infoBlue} alt="" className="w-50px h-50px my-5" />

                                <span className="text-center fs-base text-dark">Are you sure you want to submit?</span>
                            </div>
                            <div className="d-flex justify-content-between mt-10">
                                <button
                                    id="kt_password_cancel "
                                    type="button"
                                    className="btn btn-danger px-7 py-2 text-alt-red h-44 w-110 center-all "
                                    onClick={() => setConfirm(false)}
                                >
                                    <span className="indicator-label fs-base ">Cancel</span>
                                </button>
                                <button
                                    className=" btn btn-success h-44 w-110 center-all"
                                    type="button"
                                    onClick={() => formik.handleSubmit()}
                                >
                                    <span className="indicator-label fs-base ">Submit</span>
                                </button>
                            </div>
                        </div>
                    </>
                </Modal.Body>
            </Modal>
        );
    };

    return (
        <>
            <div>
                <span className="form-text">Corporate Information</span>

                <div className="corporate-container-form">
                    <div className="mt-1">
                        <div className="col-lg-6 col-form-label py-0 input-height">
                            {formik.values.mDAParent.length !== 0 && (
                                <label className="required fw-bold fs-9">MDA Parent Organization</label>
                            )}
                        </div>

                        {/* <div className="form-control form-control-solid  ">
                            <DropDown
                                mutate={mdaMutate}
                                labelKey={'registeredName'}
                                valueKey={'id'}
                                apiData={mdaNameList}
                                value={formik.values.mDAParent || ''} // Ensure value is not null
                                onChange={(e) => formik.setFieldValue('mDAParent', e || '')} // Handle empty values
                                placeholder="Select MDA Name"
                                valueStyles={{
                                    paddingLeft: '0.5rem',
                                }}
                                controlStyles={{
                                    paddingLeft: '0.5rem',
                                    paddingRight: '0.5rem',
                                    display: 'flex',
                                    alignItems: 'center',
                                    alignContent: 'center',
                                }}
                                loading={allMdaLoading}
                            />
                        </div> */}


                            <div className="form-control form-control-solid  ">
                <select
                  {...formik.getFieldProps("mDAParent")}
                  className={`no-outline ps-4 fs-8 ${
                    formik.values.mDAParent !== "" ? "text-dark" : "text-muted"
                  } `}
                >
                  <option value="">MDA Parent Organization </option>
                  {mdaNameList?.data?.map((mda, i) => {
                    return (
                      <option value={mda.id} key={i}>
                        {mda.registeredName}
                      </option>
                    );
                  })}
                </select>
              </div>

                        <div className="fv-plugins-message-container input-height">
                            <div className="fv-help-block fs-9" style={{ color: 'red' }}>
                                {formik.touched.mDAParent && formik.errors.mDAParent && formik.errors.mDAParent}
                            </div>
                        </div>
                    </div>

                    <div className="mt-1">
                        <div className="col-lg-6 col-form-label py-0 input-height">
                            {formik.values.mDASector.length !== 0 && (
                                <label className="required fw-bold fs-9">MDA Sector</label>
                            )}
                        </div>

                        <div className="form-control form-control-solid  ">
                            <select
                                {...formik.getFieldProps('mDASector')}
                                className={`no-outline ps-4 fs-8 ${
                                    formik.values.mDASector !== '' ? 'text-dark' : 'text-muted'
                                } `}
                            >
                                <option value="">Select MDA Sector *</option>
                                {getSector?.data?.map((sector, i) => {
                                    return <option value={sector.id}>{sector.description}</option>;
                                })}
                            </select>
                        </div>

                        <div className="fv-plugins-message-container input-height">
                            <div className="fv-help-block fs-9" style={{ color: 'red' }}>
                                {formik.touched.mDASector && formik.errors.mDASector && formik.errors.mDASector}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="corporate-container-form">
                    <div className="mt-1">
                        <div className="col-lg-6 col-form-label py-0 input-height">
                            {formik.values.registeredEntityName.length !== 0 && (
                                <label className="required fw-bold fs-9">Registered Entity Name</label>
                            )}
                        </div>

                        <div className="form-control form-control-solid  ">
                            <input
                                type="text"
                                placeholder="  Registered Entity Name  *  "
                                className={'no-outline ps-4 text-dark fs-8'}
                                {...formik.getFieldProps('registeredEntityName')}
                            />
                        </div>

                        <div className="fv-plugins-message-container input-height">
                            <div className="fv-help-block fs-9" style={{ color: 'red' }}>
                                {formik.touched.registeredEntityName &&
                                    formik.errors.registeredEntityName &&
                                    formik.errors.registeredEntityName}
                            </div>
                        </div>
                    </div>

                    <div className="mt-1">
                        <div className="col-lg-6 col-form-label py-0 input-height">
                            {formik.values.mdaAdminCode.length !== 0 && (
                                <label className="required fw-bold fs-9">Administrative Code</label>
                            )}
                        </div>

                        <div className="form-control form-control-solid  ">
                            <input
                                type="text"
                                placeholder="Administrative Code  *  "
                                className={'no-outline ps-4 text-dark fs-8'}
                                {...formik.getFieldProps('mdaAdminCode')}
                            />
                        </div>

                        <div className="fv-plugins-message-container input-height">
                            <div className="fv-help-block fs-9" style={{ color: 'red' }}>
                                {formik.touched.mdaAdminCode &&
                                    formik.errors.mdaAdminCode &&
                                    formik.errors.mdaAdminCode}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="corporate-container-form" style={{ marginBottom: '2rem' }}>
                    <div className="mt-1">
                        <div className="col-lg-12 col-form-label py-0 input-height">
                            {formik.values.decription.length !== 0 && (
                                <label className="required fw-bold fs-9">Description</label>
                            )}
                        </div>

                        <div className="form-control form-control-solid  ">
                            <textarea
                                rows={6}
                                placeholder="MDA Mandate * &#10;"
                                className={'no-outline ps-2 text-dark fs-8 w-90'}
                                {...formik.getFieldProps('decription')}
                            />
                        </div>

                        <div className="fv-plugins-message-container input-height">
                            <div className="fv-help-block fs-9" style={{ color: 'red' }}>
                                {formik.touched.decription && formik.errors.decription && formik.errors.decription}
                            </div>
                        </div>
                    </div>
                </div>

                <hr style={{ padding: '1rem' }} />

                <div>
                    <span className="form-text" style={{ marginBottom: '1rem' }}>
                        Contact
                    </span>

                    <div className="corporate-container-form">
                        <div className="mt-1">
                            <div className="col-lg-12 col-form-label py-0 input-height">
                                {formik.values.entityPhysicalAddress.length !== 0 && (
                                    <label className="required fw-bold fs-9">Entity Physical Address</label>
                                )}
                            </div>

                            <div className="form-control form-control-solid  ">
                                <input
                                    type="text"
                                    placeholder="Entity Physical Address  * "
                                    className={'no-outline ps-4 text-dark fs-8'}
                                    {...formik.getFieldProps('entityPhysicalAddress')}
                                />
                            </div>

                            <div className="fv-plugins-message-container input-height">
                                <div className="fv-help-block fs-9" style={{ color: 'red' }}>
                                    {formik.touched.entityPhysicalAddress &&
                                        formik.errors.entityPhysicalAddress &&
                                        formik.errors.entityPhysicalAddress}
                                </div>
                            </div>
                        </div>

                        <div className="mt-1">
                            <div className="col-lg-4 col-form-label py-0 input-height">
                                {formik.values.entityCountry.length !== 0 && (
                                    <label className="required fw-bold fs-9">Entity Country</label>
                                )}
                            </div>

                            <div className="form-control form-control-solid  ">
                                <input
                                    type="text"
                                    placeholder="Entity Country *  "
                                    className={'no-outline ps-4 text-dark fs-8'}
                                    {...formik.getFieldProps('entityCountry')}
                                />
                            </div>

                            <div className="fv-plugins-message-container input-height">
                                <div className="fv-help-block fs-9" style={{ color: 'red' }}>
                                    {formik.touched.entityCountry &&
                                        formik.errors.entityCountry &&
                                        formik.errors.entityCountry}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="corporate-container-form">
                        <div className="mt-1">
                            <div className="col-lg-12 col-form-label py-0 input-height">
                                {formik.values.entityStateProvince.length !== 0 && (
                                    <label className="required fw-bold fs-9">Entity State/Province</label>
                                )}
                            </div>

                            <div className="form-control form-control-solid  ">
                                <input
                                    type="text"
                                    placeholder="Entity State/Province * "
                                    className={'no-outline ps-4 text-dark fs-8'}
                                    {...formik.getFieldProps('entityStateProvince')}
                                />
                            </div>

                            <div className="fv-plugins-message-container input-height">
                                <div className="fv-help-block fs-9" style={{ color: 'red' }}>
                                    {formik.touched.entityStateProvince &&
                                        formik.errors.entityStateProvince &&
                                        formik.errors.entityStateProvince}
                                </div>
                            </div>
                        </div>

                        <div className="mt-1">
                            <div className="col-lg-4 col-form-label py-0 input-height">
                                {formik.values.entityCityRegion.length !== 0 && (
                                    <label className="required fw-bold fs-9">Entity City/Region</label>
                                )}
                            </div>

                            <div className="form-control form-control-solid  ">
                                <input
                                    type="text"
                                    placeholder="Entity City/Region * "
                                    className={'no-outline ps-4 text-dark fs-8'}
                                    {...formik.getFieldProps('entityCityRegion')}
                                />
                            </div>

                            <div className="fv-plugins-message-container input-height">
                                <div className="fv-help-block fs-9" style={{ color: 'red' }}>
                                    {formik.touched.entityCityRegion &&
                                        formik.errors.entityCityRegion &&
                                        formik.errors.entityCityRegion}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="corporate-container-form">
                        <div className="mt-1">
                            <div className="col-lg-4 col-form-label py-0 input-height">
                                {formik.values.entityphoneNumber.length !== 0 && (
                                    <label className="required fw-bold fs-9">Phone Number</label>
                                )}
                            </div>

                            <div className={`form-control form-control-solid `}>
                                <img src={phone} alt="fullName" className="icon-style" />

                                <NumericFormat
                                    type="text"
                                    className="no-outline ps-2 text-dark fs-8"
                                    {...formik.getFieldProps('entityphoneNumber')}
                                    placeholder=" Phone Number*"
                                    allowNegative={false}
                                    allowLeadingZeros={true}
                                />
                            </div>

                            <div className="fv-plugins-message-container input-height" style={{ color: 'red' }}>
                                <div className="fv-help-block fs-9">
                                    {formik.touched.entityphoneNumber &&
                                        formik.errors.entityphoneNumber &&
                                        formik.errors.entityphoneNumber}
                                </div>
                            </div>
                        </div>

                        <div className="mt-1">
                            <div className="col-lg-4 col-form-label py-0 input-height">
                                {formik.values.entityEmailAddress.length !== 0 && (
                                    <label className="required fw-bold fs-9">Entity Email Address</label>
                                )}
                            </div>

                            <div className="form-control form-control-solid  ">
                                <input
                                    type="text"
                                    placeholder="Entity Email Address  * "
                                    className={'no-outline ps-4 text-dark fs-8'}
                                    {...formik.getFieldProps('entityEmailAddress')}
                                />
                            </div>

                            <div className="fv-plugins-message-container input-height">
                                <div className="fv-help-block fs-9" style={{ color: 'red' }}>
                                    {formik.touched.entityEmailAddress &&
                                        formik.errors.entityEmailAddress &&
                                        formik.errors.entityEmailAddress}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="corporate-container-form">
                        <div className="mt-1">
                            <div className="col-lg-12 col-form-label py-0 input-height">
                                {formik.values.websiteURL.length !== 0 && (
                                    <label className="fw-bold fs-9">Website URL</label>
                                )}
                            </div>

                            <div className="form-control form-control-solid  ">
                                <input
                                    type="text"
                                    placeholder="Website URL "
                                    className={'no-outline ps-4 text-dark fs-8'}
                                    {...formik.getFieldProps('websiteURL')}
                                />
                            </div>

                            <div className="fv-plugins-message-container input-height">
                                <div className="fv-help-block fs-9" style={{ color: 'red' }}>
                                    {formik.touched.websiteURL && formik.errors.websiteURL && formik.errors.websiteURL}
                                </div>
                            </div>
                        </div>

                        <div className="mt-1">
                            <div className="col-lg-4 col-form-label py-0 input-height">
                                {formik.values.twitterHandle.length !== 0 && (
                                    <label className="fw-bold fs-9">Twitter Handle</label>
                                )}
                            </div>

                            <div className="form-control form-control-solid  ">
                                <input
                                    type="text"
                                    placeholder="Twitter Handle"
                                    className={'no-outline ps-4 text-dark fs-8'}
                                    {...formik.getFieldProps('twitterHandle')}
                                />
                            </div>

                            <div className="fv-plugins-message-container input-height">
                                <span>
                                    <svg
                                        width="17"
                                        height="17"
                                        viewBox="0 0 17 17"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="mr-9"
                                    >
                                        <path
                                            d="M8 7.65698H8.5V11.157H9M14.5 8.15698C14.5 11.4707 11.8137 14.157 8.5 14.157C5.18629 14.157 2.5 11.4707 2.5 8.15698C2.5 4.84327 5.18629 2.15698 8.5 2.15698C11.8137 2.15698 14.5 4.84327 14.5 8.15698Z"
                                            stroke="#0DA4C6"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M8.375 6.15698C8.78921 6.15698 9.125 5.8212 9.125 5.40698C9.125 4.99277 8.78921 4.65698 8.375 4.65698C7.96079 4.65698 7.625 4.99277 7.625 5.40698C7.625 5.8212 7.96079 6.15698 8.375 6.15698Z"
                                            fill="#5E718D"
                                        />
                                    </svg>
                                </span>
                                <span className="twitter-text ml-3">Social media handle format eg @tmsofficial</span>
                                <div className="fv-help-block fs-9" style={{ color: 'red' }}>
                                    {formik.touched.twitterHandle &&
                                        formik.errors.twitterHandle &&
                                        formik.errors.twitterHandle}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="corporate-container-form">
                        <div className="mt-1">
                            <div className="col-lg-12 col-form-label py-0 input-height">
                                {formik.values.facebokkHandle.length !== 0 && (
                                    <label className="fw-bold fs-9">Facebook Handle</label>
                                )}
                            </div>

                            <div className="form-control form-control-solid  ">
                                <input
                                    type="text"
                                    placeholder="Facebook Handle "
                                    className={'no-outline ps-4 text-dark fs-8'}
                                    {...formik.getFieldProps('facebokkHandle')}
                                />
                            </div>

                            <div className="fv-plugins-message-container input-height">
                                <span>
                                    <svg
                                        width="17"
                                        height="17"
                                        viewBox="0 0 17 17"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="mr-9"
                                    >
                                        <path
                                            d="M8 7.65698H8.5V11.157H9M14.5 8.15698C14.5 11.4707 11.8137 14.157 8.5 14.157C5.18629 14.157 2.5 11.4707 2.5 8.15698C2.5 4.84327 5.18629 2.15698 8.5 2.15698C11.8137 2.15698 14.5 4.84327 14.5 8.15698Z"
                                            stroke="#0DA4C6"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M8.375 6.15698C8.78921 6.15698 9.125 5.8212 9.125 5.40698C9.125 4.99277 8.78921 4.65698 8.375 4.65698C7.96079 4.65698 7.625 4.99277 7.625 5.40698C7.625 5.8212 7.96079 6.15698 8.375 6.15698Z"
                                            fill="#5E718D"
                                        />
                                    </svg>
                                </span>
                                <span className="twitter-text ml-3">Social media handle format eg @tmsofficial</span>
                                <div className="fv-help-block fs-9" style={{ color: 'red' }}>
                                    {formik.touched.twitterHandle &&
                                        formik.errors.twitterHandle &&
                                        formik.errors.twitterHandle}
                                </div>
                            </div>

                            <div className="fv-plugins-message-container input-height">
                                <div className="fv-help-block fs-9" style={{ color: 'red' }}>
                                    {formik.touched.facebokkHandle &&
                                        formik.errors.facebokkHandle &&
                                        formik.errors.facebokkHandle}
                                </div>
                            </div>
                        </div>

                        <div className="mt-1">
                            <div className="col-lg-4 col-form-label py-0 input-height">
                                {formik.values.instagramHandle.length !== 0 && (
                                    <label className="fw-bold fs-9">Instagram Handle</label>
                                )}
                            </div>

                            <div className="form-control form-control-solid  ">
                                <input
                                    type="text"
                                    placeholder="Instagram Handle"
                                    className={'no-outline ps-4 text-dark fs-8'}
                                    {...formik.getFieldProps('instagramHandle')}
                                />
                            </div>

                            <div className="fv-plugins-message-container input-height">
                                <span>
                                    <svg
                                        width="17"
                                        height="17"
                                        viewBox="0 0 17 17"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="mr-9"
                                    >
                                        <path
                                            d="M8 7.65698H8.5V11.157H9M14.5 8.15698C14.5 11.4707 11.8137 14.157 8.5 14.157C5.18629 14.157 2.5 11.4707 2.5 8.15698C2.5 4.84327 5.18629 2.15698 8.5 2.15698C11.8137 2.15698 14.5 4.84327 14.5 8.15698Z"
                                            stroke="#0DA4C6"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M8.375 6.15698C8.78921 6.15698 9.125 5.8212 9.125 5.40698C9.125 4.99277 8.78921 4.65698 8.375 4.65698C7.96079 4.65698 7.625 4.99277 7.625 5.40698C7.625 5.8212 7.96079 6.15698 8.375 6.15698Z"
                                            fill="#5E718D"
                                        />
                                    </svg>
                                </span>
                                <span className="twitter-text ml-3">Social media handle format eg @tmsofficial</span>
                                <div className="fv-help-block fs-9" style={{ color: 'red' }}>
                                    {formik.touched.instagramHandle &&
                                        formik.errors.instagramHandle &&
                                        formik.errors.instagramHandle}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="corporate-container-form">
                        <div className="mt-1">
                            <div className="col-lg-12 col-form-label py-0 input-height">
                                {formik.values.linkedInHandle.length !== 0 && (
                                    <label className="fw-bold fs-9">LinkedIn Handle</label>
                                )}
                            </div>

                            <div className="form-control form-control-solid  ">
                                <input
                                    type="text"
                                    placeholder="LinkedIn Handle "
                                    className={'no-outline ps-4 text-dark fs-8'}
                                    {...formik.getFieldProps('linkedInHandle')}
                                />
                            </div>

                            <div className="fv-plugins-message-container input-height">
                                <span>
                                    <svg
                                        width="17"
                                        height="17"
                                        viewBox="0 0 17 17"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="mr-9"
                                    >
                                        <path
                                            d="M8 7.65698H8.5V11.157H9M14.5 8.15698C14.5 11.4707 11.8137 14.157 8.5 14.157C5.18629 14.157 2.5 11.4707 2.5 8.15698C2.5 4.84327 5.18629 2.15698 8.5 2.15698C11.8137 2.15698 14.5 4.84327 14.5 8.15698Z"
                                            stroke="#0DA4C6"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M8.375 6.15698C8.78921 6.15698 9.125 5.8212 9.125 5.40698C9.125 4.99277 8.78921 4.65698 8.375 4.65698C7.96079 4.65698 7.625 4.99277 7.625 5.40698C7.625 5.8212 7.96079 6.15698 8.375 6.15698Z"
                                            fill="#5E718D"
                                        />
                                    </svg>
                                </span>
                                <span className="twitter-text ml-3">Social media handle format eg @tmsofficial</span>
                                <div className="fv-help-block fs-9" style={{ color: 'red' }}>
                                    {formik.touched.twitterHandle &&
                                        formik.errors.twitterHandle &&
                                        formik.errors.twitterHandle}
                                </div>
                            </div>

                            <div className="fv-plugins-message-container input-height">
                                <div className="fv-help-block fs-9" style={{ color: 'red' }}>
                                    {formik.touched.linkedInHandle &&
                                        formik.errors.linkedInHandle &&
                                        formik.errors.linkedInHandle}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <nav className={`nav navbar-fixed-signup-bottom `} id="nav">
                    <div className="nav-center-signup">
                        {/* Nav header */}
                        <div className="nav-header-signup">
                            <button className="nav-btn-back nav-back-text" id="nav-btn" onClick={handlePreviousStep}>
                                <i className="fa fa-solid fa-arrow-left"></i>{' '}
                                <span style={{ marginLeft: '0.2rem' }}>back</span>
                            </button>{' '}
                            {/* <button className="nav-btn-Proceed" id="nav-btn">
                          Proceed
                        </button> */}
                            <button
                                id="kt_password_cancel "
                                className={`btn nav-btn-Proceed ${
                                    !(formik.isValid && formik.dirty) ? 'btn-secondary' : 'btn-success'
                                } text-white px-7 py-2`}
                                disabled={!(formik.isValid && formik.dirty)}
                                onClick={(e) => setConfirm(true)}
                            >
                                {!isLoading && <span className="indicator-label">Proceed</span>}
                                {isLoading && (
                                    <span className="indicator-progress" style={{ display: 'block' }}>
                                        Please wait...{' '}
                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                )}
                            </button>
                        </div>
                    </div>
                </nav>
            </div>

     
            {confrimModal()}
        </>
    );
};

export { StepThree };
