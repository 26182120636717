import { useMutation } from 'react-query';
import { authService } from '../service/authServices';

export interface LoginProps {
    client_id: string;
    username?: string;
    password?: string;
    grant_type: string;
    client_secret: string;
    refresh_token?: string;
}

export const useLogin = () =>
    useMutation({
        mutationFn: (dataToSend: LoginProps) => authService.login(dataToSend),
    });

export const useGetUserInfo = () =>
    useMutation({
        mutationFn: (email: string) => authService.userInfo(email),
    });

export const useGetStep = () =>
    useMutation({
        mutationFn: (step: string) => authService.getStep(step),
    });
