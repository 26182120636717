import { FC, useEffect } from 'react';
import { Navigate, Route, Routes as Switch, useLocation } from 'react-router-dom';
import { Dashboard } from '../pages/admin';
import { ForgotPassword } from '../pages/auth/ForgotPassword';
import { Login } from '../pages/auth/Login';
import { ResetPassword } from '../pages/auth/ResetPassword';
import { Signup } from '../pages/auth/Signup';
import { HelpCenter } from '../pages/helpCenter/index';
import { HomePage } from '../pages/homePage/HomePage';
import { InvoicePayments } from '../pages/payments/invoicePayments';
import { ReceiptsGenerate } from '../pages/payments/receiptsGenerate';
import { RevenuePayment } from '../pages/payments/revenuePayments';

import PrivateRoutes, { PrivateOagfAdmin } from './PrivateRoutes';
import RequestAwaitingReview from '../pages/Reviewer/RequestAwaitingReview';
// import ReviewerDashboard from "../pages/Reviewer/ReviewerDashboard";
// import MdaBalanceSheet from "../pages/admin/reports/Mda/MdaBalanceSheet";
import MdaBalanceStatement from '../pages/admin/MdaBalanceStatement';
import MdaViewStatement from '../pages/admin/MdaViewStatement';

import OAGFOverview from '../pages/admin/OAGFOverview';
// import CollectionInflow from "../pages/admin/reports/Mda/CollectionInflow";
// import CollectionOutflow from "../pages/admin/reports/Mda/CollectionOutflow";
import Payments from '../pages/admin/Payments/Payments';
import PayrollPayments from '../pages/admin/Payments/PayrollPayments/PayrollPayments';
import SalaryAll from '../pages/admin/Payments/PayrollPayments/SalaryAll';
import SalaryOnly from '../pages/admin/Payments/PayrollPayments/SalaryOnly';
import ReprocessUnpaidPayments from '../pages/admin/Payments/ReprocessUnpaidPayments/ReprocessUnpaidPayments';
import BulkPayments from '../pages/admin/Payments/RetailVendorPayment/BulkPayments';
import RetailVendorPayments from '../pages/admin/Payments/RetailVendorPayment/RetailVendorPayments';
import BulkTaxUpload from '../pages/admin/Payments/TaxPayments/BulkTaxUpload';
import KeyInTaxPayments from '../pages/admin/Payments/TaxPayments/KeyInTaxPayments';
import PensionPayments from 'app/pages/admin/Payments/PensionPayments';
import { Inflow } from '../pages/admin/reports/Inflow';
import { Outflow } from '../pages/admin/reports/outflow';
import ValueAddedTax from '../pages/admin/Payments/TaxPayments/Vat/ValueAddedTax';
import ContractorFeesAndTaxes from '../pages/admin/Payments/TaxPayments/ContractorFeesAndTaxes';
import PayAsYouEarnTax from '../pages/admin/Payments/TaxPayments/PayAsYouEarnTax';
import WithholdingTax from '../pages/admin/Payments/TaxPayments/Wht/WithholdingTax';
import BillPayments from '../pages/admin/Payments/BillPayments/BillPayments';
import ElectronicInvoicePayment from '../pages/admin/Payments/BillPayments/ElectronicInvoicePayment';
import MakeABillPayment from '../pages/admin/Payments/BillPayments/MakeABillPayment';
import MdaDirectory from '../pages/admin/MdaDirectory';
import MdaDetails from '../pages/admin/MdaDetails';
import TaxPayments from '../pages/admin/Payments/TaxPayments/TaxPayments';
import ServiceActivation from '../pages/admin/ServiceActivation';
import BusinessPreference from '../pages/admin/SetupAdministration/BusinessPreference';
import CollectionRules from '../pages/admin/SetupAdministration/CollectionRules';
import CreateService from '../pages/admin/SetupAdministration/CollectionRules/CreateService';
import { CorporateProfile } from '../pages/admin/SetupAdministration/CorporateProfile';
import { UserStory } from '../pages/homePage/UserStory';
import { Payment } from '../pages/payments';
import { MdaDirectoryPayment } from '../pages/payments/mdaDirectory';
import { MdaBalanceSheetIndex } from '../pages/admin/reports/BalanceSheet';

import RejectedPayments from '../pages/admin/Payments/ReprocessUnpaidPayments/RejectedPayments';
import { PrivacyPolicy } from '../pages/privacyPolicy';
import { TermsCondition } from '../pages/termsCondition';
import PrintInvoice from '../pages/admin/Collections/PrintInvoice';
import EditService from '../pages/admin/SetupAdministration/CollectionRules/EditService';
import UserReports from '../pages/admin/SetupAdministration/UserReports/Mda';
import OagfUserReports from '../pages/admin/SetupAdministration/UserReports/Oagf';
import ServiceDetails from '../pages/admin/ServiceDetails';
import RevenueValidation from '../pages/admin/Collections/RevenueValidation';
import { isOagfAdmin } from 'core/helpers';
import { PayableView } from '../pages/admin/Analytics/PayableView/';
import { ReceivablesView } from '../pages/admin/Analytics/ReceivablesView';
import CashForecast from '../pages/admin/Analytics/CashForecast/CashForecast';
import CollectionView from '../pages/admin/Analytics/CollectionView';
import { OutstandingPayment } from '../pages/admin/Analytics/CollectionView/OutstandingPayment';
import { Layout } from '../pages/admin/layout';
import { PaymentsReceived } from '../pages/admin/Analytics/CollectionView/PaymentsReceived';
import { PendingInflows } from '../pages/admin/Analytics/CollectionView/PendingInflows';
import { RejectedItem } from '../pages/admin/Analytics/CollectionView/RejectedItem';
import { PaymentView } from '../pages/admin/Analytics/PaymentView';
import { TotalPayables } from '../pages/admin/Analytics/PaymentView/TotalPayables';
import { PaymentApproved } from '../pages/admin/Analytics/PaymentView/PaymentApproved';
import { PaymentRejected } from '../pages/admin/Analytics/PaymentView/PaymentRejected';
import TopMda from '../pages/admin/Analytics/CollectionView/OAGF/TopMda';
// import TestD from "../pages/admin/reports/Oagf/Test";
import TopServices from '../pages/admin/Analytics/CollectionView/OAGF/TopServices';
import TopMdaSector from '../pages/admin/Analytics/CollectionView/OAGF/TopMdaSector';
import OagfExpenseReceiptView from '../pages/admin/Analytics/OagfExpenseReceiptView/OagfExpenseReceiptView';
import UserManagement from '../pages/admin/SetupAdministration/UserManagement/UserManagement';
import SingleKeyIn from '../pages/admin/Payments/PensionPayments/SingleKeyIn';
import BulkUpload from '../pages/admin/Payments/PensionPayments/BulkUpload';
import RejectedPaymentsDetails from '../pages/admin/Payments/ReprocessUnpaidPayments/RejectedPaymentsDetails';
import RoleResourceManagement from '../pages/admin/SetupAdministration/RoleResourceManagement/RoleResourceManagement';
import ContractPayment from '../pages/admin/Payments/TaxPayments/ContractPayment/ContractPayment';
import StampDuty from '../pages/admin/Payments/TaxPayments/StampDuty/StampDuty';
import VatSingleKeyIn from '../pages/admin/Payments/TaxPayments/Vat/VatSingleKeyIn';
import VatBulkUpload from '../pages/admin/Payments/TaxPayments/Vat/VatBulkUpload';
import FailedPayments from '../pages/admin/Payments/ReprocessUnpaidPayments/failedPayments';
import WhtSingleKeyIn from '../pages/admin/Payments/TaxPayments/Wht/WhtSingleKeyIn';
import WhtBulkUpload from '../pages/admin/Payments/TaxPayments/Wht/WhtBulkUpload';
import StampDutySingleKeyIn from '../pages/admin/Payments/TaxPayments/StampDuty/StampDutySingleKeyIn';
import StampDutyBulkUpload from '../pages/admin/Payments/TaxPayments/StampDuty/StampDutyBulkUpload';
import { HomeLayout } from '../pages/admin/layout/homeLayout';
import SuccessPayment from '../pages/payments/SuccessPayment';
import ContractSingleKeyIn from '../pages/admin/Payments/TaxPayments/ContractPayment/ContractSingleKeyIn';
import ContractBulkUpload from '../pages/admin/Payments/TaxPayments/ContractPayment/ContractBulkUpload';
import PayAsYouEarn from '../pages/admin/Payments/TaxPayments/PayAsYouEarn/PayAsYouEarn';
import PayeSingleKeyIn from '../pages/admin/Payments/TaxPayments/PayAsYouEarn/PayeSingleKeyIn';
import PayeBulkUpload from '../pages/admin/Payments/TaxPayments/PayAsYouEarn/PayeBulkUpload';
import RequestAwaitingPensionReview from '../pages/Reviewer/RequestAwaitingPensionReview';
import RequestAwaitingVATReview from '../pages/Reviewer/RequestAwaitingVATReview';
import { PaymentReports } from 'app/pages/admin/reports/PaymentReports';
import { ReviewerPreviewService } from 'app/pages/admin/SetupAdministration/CollectionRules/ReviewerPreviewService';
import { ChartofAccount } from 'app/pages/admin/ChartofAccount';
import { Project } from 'app/pages/admin/Project';
import { CreateProject } from 'app/pages/admin/Project/CreateProject';
import { ProjectDetails } from 'app/pages/admin/Project/ViewProject/ProjectDetails';
import { BasicDetailsEdit } from 'app/pages/admin/Project/EditProject/BasicDetailsEdit';
import { ManageUsers } from 'app/pages/admin/Project/EditProject/ManageUsers';
import VoxePayment from 'app/pages/payments/components/VoxePayment';
import { ManageBankHome } from 'app/pages/admin/BankAccounts/Manage/ManageHome';
import { CreateNewBank } from 'app/pages/admin/BankAccounts/Manage/CreateNewBank';
import { InflowReportController } from 'app/pages/admin/reports/PaymentReports/InflowController';
import OutflowController from 'app/pages/admin/reports/PaymentReports/OutflowController';
import { Unauthorized } from 'app/routing/Unauthorized';

const NotFound = () => {
    return (
        <div className="error px-3">
            <h1 className="fs-3x text-center">404 - Page Not Found</h1>
            <p className="fs-6 text-dark text-center">Sorry, the page you are looking for could not be found.</p>
        </div>
    );
};

const Routes: FC = () => {
    const location = useLocation();

    useEffect(() => {
        const routeName = location.pathname.replace('/', '');

        const formatTitle = (title: string) => {
            title =
                location.pathname === '/'
                    ? 'Home'
                    : location.pathname === '/admin'
                      ? 'Dashboard'
                      : title
                            .split('/')
                            .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
                            .join(' ');

            // Remove '/' from the beginning and end of the title
            title = title.replace(/^\//, '').replace(/\/$/, '');

            // Replace '/' with ' ' in the title
            title = title.replace(/\//g, ' ');

            // Replace dashes with spaces
            title = title.replace(/-/g, ' ');

            // Remove 'auth' from the title if it exists
            title = title.replace(/auth/gi, '');
            // Remove 'reports' from the title if it exists
            title = title.replace(/reports/gi, '');
            // Remove 'collections' from the title if it exists
            title = title.replace(/collections/gi, '');
            // Remove 'setup administration' from the title if it exists
            title = title.replace(/setup administration/gi, '');

            title = title.replace(/admin /gi, ''); // Replace '/admin' with 'Dashboard'

            return title.charAt(0).toUpperCase() + title.slice(1);
        };

        document.title = `${formatTitle(routeName)}`;
    }, [location]);
    return (
        <Switch>
            <Route path="/" element={<UserStory />} />
            <Route path="/mda-home" element={<HomePage />} />

            <Route path="/auth/login" element={<Login />} />
            <Route path="/auth/forgot-password" element={<ForgotPassword />} />
            <Route path="/auth/reset-password" element={<ResetPassword />} />
            <Route path="/auth/register" element={<Signup />} />
            <Route path="/help-center" element={<HelpCenter />} />
            <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
            <Route path="/termsConditions" element={<TermsCondition />} />
            <Route path="/home" element={<Navigate to="/" />} />
            <Route path="/voxePayment" element={<VoxePayment />} />

            {/* <Route path="/test" element={<TestD />} /> */}

            <Route element={<HomeLayout />}>
                <Route path="/revenue" element={<RevenuePayment />} />
                <Route path="/payment" element={<Payment />} />
                <Route path="/mda-directory" element={<MdaDirectoryPayment />} />
                <Route path="/invoice" element={<InvoicePayments />} />
                <Route path="/receipts" element={<ReceiptsGenerate />} />
                <Route path="/revenuePayment/success" element={<SuccessPayment />} />
                <Route path="*" element={<NotFound />} />
            </Route>

            <Route path="/admin" element={<PrivateRoutes />}>
                {/* All protected mda routes should start in here */}
                <Route element={<Layout />}>
                    <Route path="" element={<Dashboard />} />

                    <Route path="service-details/:id" element={<ReviewerPreviewService />} />

                    <Route path="setup-administration">
                        <Route path="corporate-profile" element={<CorporateProfile />} />
                        <Route path="role-resource-management" element={<RoleResourceManagement />} />
                        <Route path="user-management" element={<UserManagement />} />
                        <Route path="business-preference" element={<BusinessPreference />} />
                        <Route path="collection-rules" element={<CollectionRules />} />
                        <Route path="create-collection" element={<CreateService />} />
                        <Route path="edit-collection/:id" element={<EditService />} />
                        <Route path="user-reports" element={isOagfAdmin() ? <OagfUserReports /> : <UserReports />} />
                    </Route>

                    <Route path="collections">
                        <Route path="print-invoice" element={<PrintInvoice />} />
                        <Route path="revenue-validation" element={<RevenueValidation />} />
                    </Route>

                    <Route path="payment">
                        <Route path="retail">
                            <Route path="" element={<Payments />} />
                            <Route path="single" element={<RetailVendorPayments />} />
                            <Route path="bulk" element={<BulkPayments />} />
                        </Route>

                        <Route path="tax-payments">
                            <Route path="" element={<TaxPayments />} />
                            <Route path="vat">
                                <Route path="" element={<ValueAddedTax />} />
                                <Route path="single" element={<VatSingleKeyIn />} />
                                <Route path="bulk" element={<VatBulkUpload />} />
                            </Route>
                            <Route path="wht">
                                <Route path="" element={<WithholdingTax />} />
                                <Route path="single" element={<WhtSingleKeyIn />} />
                                <Route path="bulk" element={<WhtBulkUpload />} />
                            </Route>
                            <Route path="stamp-duty">
                                <Route path="" element={<StampDuty />} />
                                <Route path="single" element={<StampDutySingleKeyIn />} />
                                <Route path="bulk" element={<StampDutyBulkUpload />} />
                            </Route>
                            <Route path="paye">
                                <Route path="" element={<PayAsYouEarn />} />
                                <Route path="single" element={<PayeSingleKeyIn />} />
                                <Route path="bulk" element={<PayeBulkUpload />} />
                            </Route>
                        </Route>

                        <Route path="contract">
                            <Route path="" element={<ContractPayment />} />
                            <Route path="single" element={<ContractSingleKeyIn />} />
                            <Route path="bulk" element={<ContractBulkUpload />} />
                        </Route>

                        <Route path="key-in-tax-payments" element={<KeyInTaxPayments />} />
                        <Route path="bulk-tax-upload" element={<BulkTaxUpload />} />
                        <Route path="paye" element={<PayAsYouEarnTax />} />
                        <Route path="contract-fee" element={<ContractorFeesAndTaxes />} />

                        <Route path="pension-payments">
                            <Route path="" element={<PensionPayments />} />
                            <Route path="single" element={<SingleKeyIn />} />
                            <Route path="bulk" element={<BulkUpload />} />
                        </Route>

                        <Route path="bill" element={<BillPayments />} />
                        <Route path="electronic-invoice-payment" element={<ElectronicInvoicePayment />} />
                        <Route path="bill-payment" element={<MakeABillPayment />} />
                        <Route path="payroll-payments" element={<PayrollPayments />} />
                        <Route path="salary-only" element={<SalaryOnly />} />
                        {/* <Route path="salary-all" element={<SalaryAll />} /> */}
                        <Route path="reprocess-unpaid-payments" element={<ReprocessUnpaidPayments />} />
                        <Route path="rejected-payments" element={<RejectedPayments />} />

                        <Route path="rejected-payments-details" element={<RejectedPaymentsDetails />} />

                        <Route path="failed-transaction" element={<FailedPayments />} />

                        <Route path="reprocess-unpaid-payments" element={<ReprocessUnpaidPayments />} />
                    </Route>

                    <Route path="reports">
                        <Route path="payment-report" element={<PaymentReports />} />
                        <Route path="inflow-report" element={<InflowReportController />} />
                        <Route path="outflow-report" element={<OutflowController />} />
                        <Route path="payment-report/inflow" element={<Inflow />} />
                        <Route path="payment-report/outflow" element={<Outflow />} />

                        <Route path="mda-balance-sheet" element={<MdaBalanceSheetIndex />} />
                    </Route>

                    <Route path="analytics">
                        {/* <Route
              path="mda-payable"
              element={<Navigate to="/admin/analytics/payableView" replace />}
            /> */}
                        <Route path="mda-payable" element={<PayableView />} />

                        <Route path="payableView" element={<PayableView />} />

                        <Route path="receivable" element={<ReceivablesView />} />
                        <Route path="cash-forecast" element={<CashForecast />} />
                        <Route path="collection-view">
                            <Route path="" element={<CollectionView />} />
                            <Route path="outstanding-payment" element={<OutstandingPayment />} />
                            <Route path="payments-received" element={<PaymentsReceived />} />
                            <Route path="pending-inflows" element={<PendingInflows />} />
                            <Route path="rejected-item" element={<RejectedItem />} />

                            <Route element={<PrivateOagfAdmin />}>
                                <Route path="top-mda" element={<TopMda />} />
                                <Route path="top-services" element={<TopServices />} />
                                <Route path="top-sector" element={<TopMdaSector />} />
                            </Route>
                        </Route>
                        <Route path="payment-view">
                            <Route path="" element={<PaymentView />} />
                            <Route path="total-payables" element={<TotalPayables />} />
                            <Route path="payments-approved" element={<PaymentApproved />} />
                            <Route path="payments-rejected" element={<PaymentRejected />} />
                        </Route>
                    </Route>

                    <Route path="mda-balance-statement">
                        <Route path="" element={<MdaBalanceStatement />} />
                        <Route path="view" element={<MdaViewStatement />} />
                    </Route>

                    <Route path="reviewer">
                        <Route path="request-awaiting-review/:requestId" element={<RequestAwaitingReview />}></Route>

                        <Route path="pension/:requestId" element={<RequestAwaitingPensionReview />}></Route>

                        <Route path="vat/:requestId" element={<RequestAwaitingVATReview />}></Route>
                    </Route>

                    <Route path="mda-directory">
                        <Route path="" element={<MdaDirectory />} />
                        <Route path="mda-details/:id" element={<MdaDetails />} />
                    </Route>

                    <Route path="project">
                        <Route path="" element={<Project />} />
                        <Route path="create" element={<CreateProject />} />
                        <Route path="view/:id" element={<ProjectDetails />} />
                        <Route path="edit">
                            <Route path="" element={<Project />} />
                            <Route path="basic-details/:id" element={<BasicDetailsEdit />} />
                            <Route path="manage-users/:id" element={<ManageUsers />} />
                        </Route>
                    </Route>
                    {/* All mda routes should end  here */}

                    {/* All private oagf Admin only  routes should be in here */}
                    <Route element={<PrivateOagfAdmin />}>
                        <Route path="oagf-overview">
                            <Route path="" element={<OAGFOverview />} />
                            <Route path="service-activation" element={<ServiceActivation />} />

                            <Route path="chart-of-account" element={<ChartofAccount />} />
                            <Route path="view-service/:id/:id" element={<ServiceDetails />} />
                        </Route>
                        {/* mda directory for admin */}

                        {/* reports */}
                        {/* <Route path="reports">
              <Route path="inflow/oagf" element={<CollectionInflowOAGF />} />
              <Route path="outflow/oagf" element={<CollectionOutflowOagf />} />
              <Route path="oagf-balance-sheet" element={<OagfBalanceSheet />} />
            </Route> */}

                        {/* analytics */}
                        <Route path="analytics">
                            <Route path="expense-receipt-view/oagf" element={<OagfExpenseReceiptView />} />
                        </Route>

                        <Route path="bank-accounts">
                            <Route path="manage" element={<ManageBankHome />} />
                            <Route path="create" element={<CreateNewBank />} />
                        </Route>
                    </Route>
                </Route>
            </Route>
            <Route element={<Layout />}>
                <Route path="unauthorized" element={<Unauthorized />} />
            </Route>
        </Switch>
    );
};

export { Routes };
