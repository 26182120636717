import React from 'react';
import { useNavigate } from 'react-router-dom';

export const Unauthorized = () => {
    const navigate = useNavigate();
    return (
        <div className="error   h-100">
            <h1 className="fs-3x text-center">Error !!!</h1>
            <p className="fs-6 text-dark text-center">Sorry, you are not authorized to view this page</p>

            <button
                type="button"
                className=" btn btn-white px-7 py-2 h-44 w-110 rounded-1 border-success "
                onClick={() => navigate(-1)}
            >
                <span className="indicator-label fs-base text-success ">Back</span>
            </button>
        </div>
    );
};
